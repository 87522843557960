<template>
    <div class="content-box">
        <div class="container">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>协同管理</el-breadcrumb-item>
                <el-breadcrumb-item>协同设计</el-breadcrumb-item>
                <el-breadcrumb-item>委托设计</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="reBack" v-show="this.desginId !== undefined" @click="$router.push('/Data/contract')" type="primary"
                >返回</el-button
            >
            <!-- <div class="button-gutter">
      <el-button type="primary" @click="addDesign">新增委托</el-button>
    </div> -->

            <div class="content_table">
                <el-table :data="tableData" border max-height="600">
                    <el-table-column align="center" width="50" label="序号">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="entrustDesign.taskName" align="center" label="任务名称"> </el-table-column>
                    <el-table-column prop="userInfo.company" align="center" label="受托公司"> </el-table-column>
                  <el-table-column prop="userInfo.name" align="center" label="负责人"> </el-table-column>
                  <el-table-column prop="entrustDesign.deliveryDate" align="center" label="交付日期"> </el-table-column>
                  <el-table-column prop="entrustDesign.state" align="center" label="任务状态">
                      <template slot-scope="scope">
                          <span>{{ scope.row.entrustDesign.state == 0? '未完成' : '已完成' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <el-button style="margin-right: 12px" size="mini" @click="ResetDesgin(scope.row, scope.$index)">
                                编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 新增委托 -->
            <el-dialog title="新增" :visible.sync="dialogVisible" :before-close="Cancel" width="500px">
                <el-form ref="elForm" :model="designFrom" label-position="left">
                    <el-form-item label="任务名称" required>
                        <el-input
                            v-model="designFrom.taskName"
                            placeholder="请输入任务名称"
                            clearable
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="交付日期" required>
                        <el-date-picker
                            v-model="designFrom.deliveryDate"
                            format="yyyy-MM-dd  HH:mm"
                            value-format="yyyy-MM-dd HH:mm"
                            :style="{ width: '100%' }"
                            placeholder="请选择交付日期"
                            clearable
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="所属合同" required>
                        <el-select v-model="designFrom.contractId" placeholder="请选择">
                            <el-option v-for="item in statusOptions" :key="item.value" :label="item.lable" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="受托公司" required prop="entrustCompanyName">
                        <el-select v-model="designFrom.entrustCompanyName" placeholder="请选择">
                            <el-option v-for="item in companyOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="任务状态" required>
                        <el-select v-model="designFrom.state" placeholder="请选择">
                            <el-option v-for="item in stateOptions" :key="item.value" :label="item.lable" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="footer">
                    <el-button @click="Cancel">取 消</el-button>
                    <el-button type="primary" @click="SendData(designFrom)">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="编辑" width="500px" :visible.sync="resetdialogVisible" :before-close="Cancel">
                <el-form ref="elForm" :model="resetFrom" label-position="left">
                    <el-form-item label="任务名称" required>
                        <el-input v-model="resetFrom.taskName" placeholder="请输入任务名称" clearable :style="{ width: '100%' }"></el-input>
                    </el-form-item>
                    <el-form-item label="任务状态" required>
                        <el-select v-model="resetFrom.state" placeholder="请选择">
                            <el-option v-for="item in stateOptions" :key="item.value" :label="item.lable" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="footer">
                    <el-button @click="Cancel">取 消</el-button>
                    <el-button type="primary" @click="ResetData(resetFrom)">确 定</el-button>
                </div>
            </el-dialog>
            <el_pagination :total="total" :num="num" @page-limit="page_limit"></el_pagination>
        </div>
    </div>
</template>

<script>
import { documentList, documentRemove, designList, addDesing, resetDesing, deleteDesginList, contractlist } from '@/api/document';
import { getUserList } from '@/api/user';

export default {
    name: 'CommissionedDesign',
    components: {},
    data() {
        return {
            dialogVisible: false,
            resetdialogVisible: false,
            tableData: [],
            designFrom: {},
            resetFrom: {},
            companyOptions: [],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            num: 0,
            userName: localStorage.getItem('name'),
            data: {},
            desginId: '',
            statusOptions: [],
            stateOptions: [
                {
                    lable: '已完成',
                    value: 1
                },
                {
                    lable: '未完成',
                    value: 0
                }
            ]
        };
    },
    created() {},
    mounted() {
        //console.log(JSON.parse(this.$route.query.id));
        this.desginId = this.$route.query.id;
        //  console.log(this.desginId);
        this.getFileList();
        this.getcontractList();
        this.getGYSUser();
    },
    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            this.getFileList();
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        //获取供应商公司
        async getGYSUser() {
            let res = await getUserList();
            if (res.code == 200) {
                let userList = [];

                res.data.forEach((item) => {
                    if (item.name !== this.userName && item.resume == 3) {
                        let userData = {};
                        //console.log(item)
                        userData.value = item.id;
                        userData.label = item.company;
                        userList.push(userData);
                    }
                });
                //    console.log(userList);
                this.companyOptions = userList;
            }
        },
        // 获取合同表
        async getcontractList() {
            // let limit = this.pageSize;
            // let page = this.currentPage;
            let res = await contractlist();
            let optionData = {};
            //  let optionList  = []
            if (res.code == 200) {
                res.data.forEach((item) => {
                    optionData.lable = item.contractNo;
                    optionData.value = item.id;
                    this.statusOptions.push(optionData);
                    optionData = {};
                });
                // this.statusOptions=optionList
                // console.log(this.statusOptions)
            }
        },

        //获取委托表
        async getFileList() {
            let limit = this.pageSize;
            let page = this.currentPage;
            let contractId = this.desginId;
            if (contractId == undefined || contractId == '') {
                const res = await designList({
                    limit,
                    page
                });
                if (res.code == 200) {
                    this.tableData = res.data;
                    this.total = res.total;
                    this.num = parseInt(this.total / limit + 1);
                }
            } else if (contractId !== undefined || contractId !== '') {
                const res = await designList({
                    limit,
                    page,
                    contractId
                });
                if (res.code == 200) {
                    this.tableData = res.data;
                    this.total = res.total;
                    this.num = parseInt(this.total / limit + 1);
                }
            }
        },
        // 删除新增委托
        async deleteFile(row, $idnex) {
            let id = row.id;

            const res = await deleteDesginList({
                id
            });
            if (res.code === 200) {
                this.$message.success('删除成功');
            } else {
                this.$message.error('删除失败');
            }
            await this.getFileList();
        },
        //新增弹框
        addDesign() {
            this.dialogVisible = true;
        },
        //确定新增
        async SendData(designFrom) {
            console.log(designFrom);

            let res = await addDesing(designFrom);
            if (res.code == 200) {
                this.$message.success('新增成功');
                this.getFileList();
                this.dialogVisible = false;
                this.designFrom = {};
            } else {
                this.$message.error(res.message);
            }
        },
        // 编辑弹框
        ResetDesgin(row, $index) {
             let  id  = row.entrustDesign.id

              let  data  =  {
                id:row.entrustDesign.id,
                taskName:row.entrustDesign.taskName,
                state:row.entrustDesign.state,

              }
         this.resetFrom =JSON.parse(JSON.stringify(data))
            this.resetdialogVisible = true;

        },
        //编辑新增委托
        async ResetData(restFrom) {
            let res = await resetDesing(restFrom);
            if (res.code == 200) {
                this.$message.success('编辑成功');
                this.getFileList();
                this.resetdialogVisible = false;
                this.resetFrom = {};
            } else {
                this.$message.error(res.message);
            }
        },
        //取消新增
        Cancel() {
            this.resetdialogVisible = false;
            this.dialogVisible = false;
            this.designFrom = {};
            this.resetFrom = {};
        },
        NavDeltails(row, $index) {
            // let  taskName  = row.taskName
            // let id  = row.id
            // let entrustCompanyName  = row.deliveryDate
            // let deliveryDate   =row.deliveryDate
            // let state  = row.state

            this.$router.push({
                // path: `commissioned-deltalis/${row.id}/${row.state}/${row.entrustCompanyName}/${row.deliveryDate}`,
                path: `commissioned-deltalis`,
                query: { row: JSON.stringify(row) }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.button-gutter {
    margin: 20px 0;
}
.deltalis {
    margin-right: 10px;
}
::v-deep .el-dialog__header {
    text-align: center;
}
.footer {
    width: 300px;
    text-align: center;
    margin: 0 auto;
}
.content_table {
    margin-top: 70px;
}
.reBack {
    float: right;

    margin-top: 10px;
}
</style>

